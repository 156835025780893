* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.banner-img {
  object-fit: cover;
  width: 100%;
  height: 50%;
  position: fixed;
  z-index: -1;
}

.home {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}